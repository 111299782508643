import {
  getAllStreamersPath,
  getLoginPath,
  getStreamerProfilePath,
} from "@/lib/getRoutePath"
import { ArrowRight } from "lucide-react"
import { Link } from "react-router-dom"
import { TTalent } from "../talents"
import { useTranslation } from "react-i18next"
import { Button } from "@/components/ui/button"

export function Community({ streamers }: { streamers: TTalent[] }) {
  const { t } = useTranslation()
  return (
    <section className="mb-10 flex flex-col">
      <div className="relative flex h-screen flex-col">
        <h2 className="mb-16 text-4xl font-bold text-white">
          {t("LP.Community.title")}
        </h2>
        <div className="absolute bottom-0 z-10 flex h-[20%] w-full items-end justify-center bg-gradient-to-t from-black/90 via-black/50 to-transparent">
          <Link to={getAllStreamersPath()}>
            <Button variant={"pretty"} className="uppercase text-black">
              {t("LP.Community.DuoStreamers")} <ArrowRight className="mr-2" />
            </Button>
          </Link>
        </div>
        <div className="relative flex w-full flex-wrap justify-center gap-6 overflow-hidden p-2">
          {streamers.map((streamer) => (
            <Link to={getStreamerProfilePath(streamer.name)} key={streamer.id}>
              <div className="relative h-[16rem] w-[12rem] overflow-hidden rounded-2xl bg-[#1A1A1A]">
                <div
                  style={{
                    backgroundImage: `url(${streamer.avatar})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                  className="absolute inset-0 z-0 transition-transform duration-300 ease-out will-change-transform hover:scale-110"
                  role="img"
                />
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div className="my-10 flex h-20 items-center justify-between overflow-hidden rounded-xl bg-[#107272] md:h-40">
        <span className="p-10 text-sm md:text-3xl">{t("LP.CTA")}</span>
        <Link
          className="flex h-full w-2/6 items-center justify-center rounded-none bg-[#13A8A8] p-4 text-xs font-bold uppercase text-black transition-all hover:scale-110 hover:text-black md:text-base"
          to={getLoginPath()}
        >
          {t("LP.CTAButton")} <ArrowRight />
        </Link>
      </div>
    </section>
  )
}
