import { getOverviewPath } from "@/lib/getRoutePath"
import { Link } from "react-router-dom"

export function HeaderView() {
  return (
    <header className="flex h-[50px] items-center bg-background">
      <div className="flex items-center justify-between px-6 md:container">
        <Link
          to={getOverviewPath()}
          className="text-xl font-bold tracking-widest text-white"
        >
          DUOTOPLAY
        </Link>
      </div>
    </header>
  )
}
