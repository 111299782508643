import { useContext, useEffect } from "react"
import { Trans, useTranslation } from "react-i18next"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { AuthContext } from "../../context/auth"
import { publicApi } from "../../services/api"
import { Helmet } from "react-helmet"
import { Button } from "@/components/ui/button"
import { TwitchIcon } from "@/components/TwitchIcon"
import { getOverviewPath, getTermsPath } from "@/lib/getRoutePath"
import { ChangeLanguage } from "@/components/ChangeLanguage"

export function Login() {
  const {
    token,
    user: { userId },
  } = useContext(AuthContext)
  const { t } = useTranslation()
  const navigation = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (token && userId) {
      navigation(getOverviewPath())
    }
  }, [token, userId, navigation])

  async function handleAuthLink(type: string) {
    const { data } = await publicApi.get(`/authorize/third-party?type=${type}`)
    window.location.href = data.uri
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Helmet.login.title")}</title>
        <meta name="description" content={t("Helmet.login.description")} />
        <link rel="canonical" href={origin + location.pathname} />
      </Helmet>
      <main>
        <div className="absolute flex w-full items-center justify-between pt-4 text-white">
          <span className="pl-8 text-xl font-bold uppercase tracking-widest">
            DuoToPlay
          </span>
          <div className="pr-8">
            <ChangeLanguage />
          </div>
        </div>
        <div className="h-screen bg-black lg:grid lg:grid-cols-2">
          <div className="flex h-full items-center justify-center">
            <div className="mx-auto w-4/5 md:w-2/3">
              <h1 className="text-4xl font-bold text-white xl:text-5xl ">
                {t("login.title")}
              </h1>
              <p className="mt-4 text-sm text-gray-400">{t("login.description")}</p>
              <Button
                className="mt-8 text-black"
                variant={"pretty"}
                onClick={() => handleAuthLink("twitch")}
              >
                <TwitchIcon width={24} />
                <span>{t("login.twitch")}</span>
              </Button>
              <p className="mt-10 flex items-center gap-1 text-sm font-light text-gray-600">
                <Trans i18nKey="login.agreeTerms">
                  <Link
                    to={getTermsPath()}
                    className="text-neutral font-extrabold underline inline"
                  />
                </Trans>
              </p>
            </div>
          </div>
          <div className="hidden lg:block bg-gradient-to-l from-primary/60 to-black" />
        </div>
      </main>
    </>
  )
}
