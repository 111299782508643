import { Talents } from "@/pages/talents"
import { StreamerBanner } from "./StreamerBanner"
import { StreamerProducts } from "../StreamerOverview/StreamerProducts"

export function FanOverview() {
  return (
    <div className="space-y-10 pb-20">
      <StreamerBanner />
      <Talents />
      <StreamerProducts />
    </div>
  )
}
