import { Avatar } from "@/components/ui/avatar"
import { Link } from "react-router-dom"
import {
  BottomLeftIcon,
  BottomLeftShadowIcon,
  BottomRightIcon,
  BottomRightShadowIcon,
  TopRightIcon,
  TopRightShadowIcon,
} from "@/components/Icons/LandingPage"

import { Community } from "./Community"
import { Footer } from "@/components/layout/footers/Footer"
import { useQuery } from "@tanstack/react-query"
import { GetAllAccounts } from "@/services/request/Account"
import { ChangeLanguage } from "@/components/ChangeLanguage"
import { useTranslation } from "react-i18next"
import { getLandingPagePath, getLoginPath } from "@/lib/getRoutePath"
import { Advantages } from "./Advantages"
import { Button } from "@/components/ui/button"
export default function LandingPage() {
  const { t } = useTranslation()
  const { data: streamers } = useQuery({
    queryKey: ["streamers"],
    queryFn: () => GetAllAccounts(),
  })

  return (
    <div className="relative z-10 min-h-screen bg-black">
      <header className="container mx-auto flex items-center justify-between px-4 py-6">
        <Link
          to={getLandingPagePath()}
          className="text-2xl font-bold text-white"
        >
          DUOTOPLAY
        </Link>
        <div className="flex items-center gap-8">
          <ChangeLanguage />
          <Link to={getLoginPath()}>
            <Button variant={"pretty"} className="text-black">
              {t("LP.SignUp")}
            </Button>
          </Link>
        </div>
      </header>
      {/* Main Content */}
      <main>
        <div className="absolute h-[90vh] w-full overflow-hidden">
          <TopRightIcon className="absolute right-0 top-0" />
          <TopRightShadowIcon className="absolute right-0 top-0" />

          <BottomRightIcon className="absolute bottom-0 right-0" />
          <BottomRightShadowIcon className="absolute bottom-0 right-0" />

          <BottomLeftIcon className="absolute bottom-0 left-0" />
          <BottomLeftShadowIcon className="absolute bottom-0 left-0" />
        </div>
        <div className="container mx-auto px-4">
          <div className="relative h-[90vh] max-w-3xl flex-col pt-20 justify-center">
            {/* Avatar Group with Badge */}
            <div className="mb-8 inline-flex items-center gap-2 rounded-full bg-white/10 px-2 py-1">
              {streamers && (
                <>
                  <div className="flex -space-x-2">
                    <Avatar className="h-6 w-6 border-2 border-black">
                      <img src={streamers[0].avatar} alt="Avatar" />
                    </Avatar>
                    <Avatar className="h-6 w-6 border-2 border-black">
                      <img src={streamers[1].avatar} alt="Avatar" />
                    </Avatar>
                    <Avatar className="h-6 w-6 border-2 border-black">
                      <img src={streamers[2].avatar} alt="Avatar" />
                    </Avatar>
                  </div>
                  <span className="rounded-xl border-solid border-[#FF6B00] bg-[#FF6B00] bg-opacity-20 px-2 py-1 text-sm text-[#FF6B00]">
                    + {streamers.length} talentos
                  </span>
                </>
              )}
            </div>

            {/* Hero Text */}
            <h1 className="mb-6 text-5xl font-bold leading-snug text-white md:text-6xl">
              <strong className="bg-gradient-to-r from-primary to-white bg-clip-text text-transparent">
                {t("LP.title1")}
              </strong>
              {t("LP.title2")}
            </h1>

            {/* Subtitle */}
            <p className="mb-8 text-xl text-white/80">{t("LP.subtitle")}</p>

            {/* CTA Button */}
            <Link to={getLoginPath()}>
              <Button variant={"pretty"} className="text-black">
                {t("LP.SignUp")}
                <svg
                  className="ml-2 h-5 w-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              </Button>
            </Link>
          </div>
          <Advantages />
          <Community streamers={streamers || []} />
        </div>
        <Footer />
      </main>
    </div>
  )
}
